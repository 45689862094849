// import { createApp } from "vue";
//
// // makes sure XMLHttpRequest is included as an HTTP header value for axios calls from within Vue components
// import "./script/http/set-axios-defaults";
//
// import MiniBasket from "./components/MiniBasket.vue";
//
// if (document.getElementsByClassName("js-vue--mini-basket").length) {
//   createApp(MiniBasket).mount(".js-vue--mini-basket");
// }

// no vue components yet
console.log("⚡ Loading components...");
